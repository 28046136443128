/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductActiveIngredients {
    &-ExpandableContentHeading {
        @include desktop {
            display: none;
        }
    }

    &-ExpandableContentContent {
        [data-content-type="row"] {
            @include mobile {
                margin-bottom: 16px;
            }
        }

        .pagebuilder-column-line {
            @include mobile {
                flex-direction: column;
            }

            @include desktop {
                column-gap: 5px;
                margin-bottom: 20px;
            }

            figure {
                img {
                    max-width: 80px;
                    max-height: 80px;

                    @include mobile {
                        min-width: 80px;
                    }

                    &.pagebuilder-mobile-hidden {
                        @include mobile {
                            display: none;
                        }
                    }

                    &.pagebuilder-mobile-only {
                        @include desktop {
                            display: none;
                        }
                    }
                }
                @include mobile {
                    margin-bottom: 10px;
                }
            }
        }

        .pagebuilder-column p {
            margin-bottom: 10px;
        }

        .pagebuilder-column > div:nth-child(2) p {
            margin-bottom: 0;
        }
    }
}

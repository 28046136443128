/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductAttachments {
    &-ExpandableContentHeading {
        @include desktop {
            display: none;
        }
    }

    &-Content {
        div[data-content-type="buttons"] {
            display: flex;
            gap: 20px;

            @include mobile {
                flex-wrap: wrap;
            }
        }

        div[data-content-type="button-item"] {
            @include mobile {
                width: 100%;
            }
        }

        .pagebuilder-button-primary {
            @include button();

            --button-height: var(--hollow-button-height);
            --button-padding: 50px;
            --button-hover-height: var(--hollow-button-height);
            --button-hover-padding: 50px;

            background-color: var(--hollow-button-background);
            border-color: var(--hollow-button-border);
            border-radius: var(--hollow-button-border-radius);
            color: var(--hollow-button-color);
            text-decoration: var(--hollow-button-text-decoration);

            &:not([disabled]) {
                &:hover {
                    @include desktop {
                        background-color: var(--hollow-button-hover-background);
                        border-color: var(--hollow-button-hover-border);
                        border-radius: var(--hollow-button-hover-border-radius);
                        color: var(--hollow-button-hover-color);
                        text-decoration: var(
                            --hollow-button-hover-text-decoration
                        );
                    }
                }
            }

            span {
                display: flex;
                align-items: center;
                gap: 10px;

                &::after {
                    content: "";
                    width: 24px;
                    height: 24px;
                    display: block;
                    background-position: center center;
                    background-image: url("../../style/icons/attachment.svg");
                }
            }
        }
    }
}

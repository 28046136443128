/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductExpertAdvises {
    &-ExpandableContentHeading {
        @include desktop {
            display: none;
        }
    }

    &-ExpandableContentContent {
        .pagebuilder-column-line {
            @include mobile {
                flex-wrap: wrap;
            }
        }

        [data-content-type="heading"] {
            margin: 0 0 10px;

            @include mobile {
                font-size: 18px;
            }

            + div p {
                margin: 0;
            }
        }

        .pagebuilder-column {
            &:nth-of-type(1),
            &:nth-of-type(2) {
                @include mobile {
                    width: 50% !important;
                }
            }
            &:nth-of-type(1) {
                @include mobile {
                    padding: 0 15px 0 30px;
                }

                @include desktop {
                    margin-right: 20px;
                }
            }

            &:nth-of-type(2) {
                @include desktop {
                    margin-top: 70px;
                }
                @include mobile {
                    align-self: center !important;
                }
            }

            &:last-of-type {
                @include mobile {
                    width: 100% !important;
                    margin: 10px 0 16px;
                }
            }

            img {
                &.pagebuilder-mobile-hidden {
                    @include mobile {
                        display: none;
                    }
                }

                &.pagebuilder-mobile-only {
                    @include desktop {
                        display: none;
                    }
                }
            }
            p {
                font-size: 16px;
                font-weight: 400;
                color: $text-color-2;
            }
        }

        .title {
            margin-top: 36px;

            &:first-of-type {
                margin-top: 0;
            }

            p {
                margin: 0;
                font-size: 18px;
                font-weight: 400;
                color: $black;
            }

            + div p {
                margin-top: 10px;
                margin-bottom: 0;
            }
        }
    }
}

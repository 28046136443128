/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --category-list-top-inset: -56px;
}

.CategoryProductList {
    @include desktop {
        inset-block-start: var(--category-list-top-inset);
        padding-block-end: 0;
        margin-top: 17px;
    }

    &-Page {
        &_layout {
            &_grid {
                @include narrow-desktop {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }

                @include wide-desktop {
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                }

                @include tablet {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }

                @include mobile {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
            }

            &_list {
                max-width: unset;

                .ProductCard-Link {
                    width: 100%;
                }
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --product-slider-offset-top: 22px;
    --product-slider-offset-bottom: 100px;
}

.ProductSlider {
    &-Title {
        margin: 0;
    }

    &-TitleWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .slick-list {
        padding: var(--product-slider-offset-top) 0 var(--product-slider-offset-bottom);
    }

    .slick-prev,
    .slick-next {
        top: calc(50% - calc((var(--product-slider-offset-top) + var(--product-slider-offset-bottom)) / 2));

        &::before {
            margin: auto;
        }
    }

    .slick-prev {
        @include mobile {
            left: -20px;
        }
    }

    .slick-next {
        @include mobile {
            right: -20px;
        }
    }

    &-HeadingSeeMoreLinkWrapper {
        @include mobile {
            text-align: center;
            margin-top: -85px;
            margin-bottom: 110px;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .ProductAttributeValue {
    &-Text label {
        &:first-of-type .input-control {
            @include desktop {
                margin-block-start: 3px;
            }
        }
    }

    &-SubLabel {
        margin-left: 16px;
        padding: 0 6px;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        border-radius: 4px;
        min-height: 17px;
        background-color: $background-color-1;
        display: inline-block;
        color: $black;
    }

    &-Link {
        width: auto;
        min-width: var(--option-size);
        margin: 16px 12px 0 0;
        padding: 0;
        border: 0;
        border-radius: var(--button-border-radius);
        display: flex;
        align-items: baseline;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        color: $text-color-2;

        &:hover {
            color: $primary-color-1;
        }
    }
}

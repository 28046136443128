/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
@mixin button-disabled {
    pointer-events: none;
    opacity: 0.25;
}

@mixin select-transition {
    transition-property: border-color, background, border-color;
    transition-duration: 150ms;
    transition-timing-function: ease-in;
}

@mixin button {
    align-items: center;
    background-color: var(--button-background);
    border-radius: var(--button-border-radius);
    cursor: pointer;
    display: inline-flex;
    color: var(--button-color);
    justify-content: center;
    height: var(--button-height);
    padding-inline: var(--button-padding);
    text-decoration: var(--button-text-decoration);
    text-transform: uppercase;
    transition-duration: .25s;
    transition-timing-function: ease-out;
    transition-property: background-color, color, border;
    will-change: background-color, color, border;
    letter-spacing: 0.05em;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    min-width: 169px;

    @include mobile {
        user-select: none;
        width: 100%;
    }

    border: {
        color: var(--button-border);
        style: solid;
        width: var(--button-border-width);
    }

    &:hover,
    &:focus {
        text-decoration: var(--button-hover-text-decoration);
    }

    &:not([disabled]):not(.Button_likeLink):not(.Button_isHollow):not(.Button_isAnchor) {
        &:hover {
            @include desktop {
                background-color: var(--button-hover-background);
                border-color: var(--button-hover-border);
                border-radius: var(--button-hover-border-radius);
                color: var(--button-hover-color);
                height: var(--button-hover-height);
                padding-inline: var(--button-hover-padding);
            }
        }
    }

    &[disabled] {
        @include button-disabled();
    }

    &_isHollow {
        --button-height: var(--hollow-button-height);
        --button-padding: var(--hollow-button-padding);
        --button-hover-height: var(--hollow-button-height);
        --button-hover-padding: var(--hollow-button-padding);

        background-color: var(--hollow-button-background);
        border-color: var(--hollow-button-border);
        border-radius: var(--hollow-button-border-radius);
        color: var(--hollow-button-color);
        text-decoration: var(--hollow-button-text-decoration);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: var(--hollow-button-hover-background);
                    border-color: var(--hollow-button-hover-border);
                    border-radius: var(--hollow-button-hover-border-radius);
                    color: var(--hollow-button-hover-color);
                    text-decoration: var(--hollow-button-hover-text-decoration);
                }
            }
        }

        &.Button_isHovered:not([disabled]) {
            color: var(--hollow-button-hover-color);
        }

        &[disabled] {
            @include button-disabled();
        }

        &.Button_medium {
            --button-height: var(--medium-button-height);
            --button-padding: var(--medium-button-padding);
            --button-hover-height: var(--medium-button-hover-height);
            --button-hover-padding: var(--medium-button-hover-padding);
        }

        &.Button_small {
            --button-height: var(--small-button-height);
            --button-padding: var(--small-button-padding);
            --button-hover-height: var(--small-button-hover-height);
            --button-hover-padding: var(--small-button-hover-padding);
        }
    }

    &_isMonochrome {
        border-color: $black;
        color: $black;

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    border-color: $text-color-3;
                    color: $black;
                }
            }
        }
    }

    &_isAnchor {
        --button-height: var(--hollow-button-height);
        --button-padding: var(--hollow-button-padding);
        --button-hover-height: var(--hollow-button-height);
        --button-hover-padding: var(--hollow-button-padding);

        background-color: var(--hollow-button-background);
        border-color: var(--hollow-button-border);
        border-radius: var(--hollow-button-border-radius);
        color: var(--hollow-button-color);
        text-decoration: var(--hollow-button-text-decoration);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: var(--button-background);
                    color: var(--button-color);
                    border-color: var(--button-border);
                    border-style: solid;
                    border-width: var(--button-border-width);
                }
            }
        }

        &.Button_isHovered:not([disabled]) {
            color: var(--hollow-button-hover-color);
        }

        &[disabled] {
            @include button-disabled();
        }

        &.Button_medium {
            --button-height: var(--medium-button-height);
            --button-padding: var(--medium-button-padding);
            --button-hover-height: var(--medium-button-hover-height);
            --button-hover-padding: var(--medium-button-hover-padding);
        }

        &.Button_small {
            --button-height: var(--small-button-height);
            --button-padding: var(--small-button-padding);
            --button-hover-height: var(--small-button-hover-height);
            --button-hover-padding: var(--small-button-hover-padding);
        }
    }

    &_isWithoutBorder {
        border: 0;
    }

    &_withArrow {
        font-weight: 400;
        font-size: 16px;
        line-height: 110%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        position: relative;

        &:active {
            text-decoration: none;
            padding: 0;
        }

        &::before {
            content: '';
            position: absolute;
            display: block;
            inset-block-end: -3px;
            width: 100%;
            height: 1px;
            border-radius: 2px;
            background-color: $primary-color-3;
        }

        &::after {
            content: '';
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-image: url("../../assets/icons/arrow-right.svg");
            margin-inline-start: 7px;
        }
    }

    &_likeLink {
        --button-border: transparent;
        --button-background: transparent;
        --button-color: #{$black};
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: #{$black};
        --button-padding: 0;
        --button-hover-padding: 0;
        --button-height: min-content;
        --button-hover-height: min-content;

        border: none;
        text-transform: none;
        margin: 0;
        padding: 0;
        text-align: start;
        min-width: unset;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: $primary-color-1;

        @include hoverable {
            &:hover,
            &:focus {
                text-decoration: none;
                padding: 0;
            }
        }
    }


    &_underlinedLink,
    &_underlinedLeftArrowLink {
        --button-border: transparent;
        --button-background: transparent;
        --button-color: #{$black};
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: #{$black};
        --button-padding: 0;
        --button-hover-padding: 0;
        --button-height: min-content;
        --button-hover-height: min-content;

        border: none;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        text-align: start;
        min-width: unset;
        font-weight: 400;
        font-size: 16px;
        line-height: 110%;
        letter-spacing: 0.05em;
        color: $black;

        &::after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            inset-inline-start: 0;
            inset-block-end: 0;
            background-color: #D04469;
        }

        @include hoverable {
            &:hover,
            &:focus {
                text-decoration: none;
                padding: 0;
            }
        }
    }

    &_underlinedLeftArrowLink {
        &::before {
            content: '';
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-image: url("../icons/arrow-long-left.svg");
            margin-inline-end: 7px;
        }
    }

    &_medium {
        --button-height: var(--medium-button-height);
        --button-padding: var(--medium-button-padding);
        --button-hover-height: var(--medium-button-hover-height);
        --button-hover-padding: var(--medium-button-hover-padding);

        font-size: 14px;
        line-height: 110%;
        min-width: 121px;
    }

    &_small {
        --button-height: var(--small-button-height);
        --button-padding: var(--small-button-padding);
        --button-hover-height: var(--small-button-hover-height);
        --button-hover-padding: var(--small-button-hover-padding);

        font-size: 12px;
        line-height: 110%;
        min-width: 92px;
    }
}

@mixin grey-page-bg {
    @include mobile {
        background: #fff;
    }

    &::before {
        z-index: -1;
        content: '';
        position: fixed;
        inset-inline-start: 0;
        inset-inline-end: 0;
        inset-block-start: 0;
        inset-block-end: 0;
        background: var(--secondary-base-color);

        @include desktop {
            content: none;
        }
    }
}

@mixin default-list {
    ul,
    ol {
        margin-inline-start: 20px;

        li {
            &::before {
                inset-inline-start: -20px;
            }
        }
    }
}

@mixin description-tags {
    li,
    span,
    div,
    p,
    dt,
    dd,
    &,
    dl {
        @content
    }
}

@mixin mobile-bottom-wrapper {
    @include mobile {
        @content;

        border-block-start: 1px solid var(--primary-divider-color);
        position: fixed;
        inset-inline-start: 0;
        width: 100%;
        z-index: 85;
        background-color: var(--color-white);
        padding-block-end: var(--footer-total-height);
        transition: transform 200ms cubic-bezier(.47, 0, .745, .715);

        .hideOnScroll & {
            transform: translateY(var(--footer-nav-height));
        }
    }
}

@mixin paragraph-l {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
}

@mixin paragraph-m {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
}

@mixin paragraph-s {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
}

@mixin paragraph-xs {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
}

@mixin text-in-x-lines($lines: 2) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    overflow: hidden;
    white-space: initial;
    text-overflow: initial;
}

@mixin grayscale($value: grayscale(100%)) {
    -webkit-filter: $value;
    filter: $value;
}

@mixin hide-scrollbar {
    scrollbar-width: none !important;
    scrollbar-color: none !important;
    
    &::-webkit-scrollbar {
        display: none;
    }
    
    &::-webkit-scrollbar-thumb {
        display: none;
    }
}

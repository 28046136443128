/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --carousel-scroll-item-height: 86px;
}

.ProductGallery {
    margin: 0;

    @include mobile {
        margin-bottom: 0;
        min-height: 420px;
    }

    @include desktop {
        max-width: 670px;
        height: 100%;
    }

    &-SliderWrapper {
        margin-top: -45px;

        @include desktop {
            min-height: 670px;
        }

        @include tablet {
            margin-top: 0;
        }

        @include desktop {
            margin-top: 0;
        }
    }

    &-Additional .CarouselScroll {
        @include desktop {
            margin-top: 20px;
            height: 124px;
        }
    }


    &-SliderImage {
        @include mobile {
            min-height: 420px;
        }
    }

    .Slider-Arrow {
        svg {
            width: 20px;
            height: 20px;
        }
    }

    .Slider-Arrow_isNext {
        right: 0;
    }

    .Slider-Arrow_isPrev {
        left: 0;
    }

    &-Additional {
        overflow: hidden;
    }

    .Slider-Crumbs {
        position: absolute;
        margin-block: 0;
        inset-block-end: 70px;
        grid-template-columns: repeat(auto-fit, 16px);
        grid-gap: 12px;
        z-index: 1;

        @include mobile {
            bottom: 5px;
        }

        .Slider-Crumb {
            width: 8px;
            height: 8px;
            background-color: $text-color-3;
            cursor: pointer;

            &_isActive {
                background-color: $black;
            }
        }

        @include desktop {
            grid-template-columns: repeat(auto-fit, 12px);
        }
    }
}

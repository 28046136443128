/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .ProductActions {
    --elements-gap: 10px;

    display: flex;
    flex-direction: column;

    &-Title {
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.72px;
        margin: 10px 0 0;

        @include desktop {
            font-size: 32px;
            letter-spacing: -0.96px;
        }
    }

    &-AlternativeName {
        color: $black;
        line-height: 140%;
        font-size: 16px;
        margin-block: 0;
        margin-block-start: var(--elements-gap);
        font-weight: 600;
    }

    &-ShortDescription {
        &,
        p {
            line-height: 140%;
            font-size: 16px;
            color: $text-color-2;
            margin: 0;
        }
    }

    &-Section {
        margin-block-start: var(--elements-gap);

        &_type_sku {
            @include mobile {
                display: block;
            }
        }
    }

    &-Sku {
        line-height: 140%;
        font-size: 16px;
        color: $text-color-2;
        margin-block: 0;

        @include mobile {
            text-align: start;
        }

        &Label {
            display: inline-block;
            min-width: 100px;
            margin-right: 4px;
        }

        &Value {
            color: $black;
        }
    }

    &-Attributes {
        .ProductConfigurableAttributes-Title {
            margin-block-start: var(--elements-gap);
        }
    }

    &-PriceWrapper {
        margin: 40px 0;

        .ProductPrice {
            @include desktop {
                min-height: unset;
            }

            &_hasDiscount {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            &-HighPrice {
                position: static;
                width: auto;
                margin: 0 10px 0 0;

                @include mobile {
                    position: absolute;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                }
            }

            &-PriceBadge {
                margin: 0 0 0 10px;
            }

            &-Price {
                align-items: center;
                font-weight: 600;
                line-height: 30px;
                font-size: 24px;

                .ProductPrice-PriceBadge {
                    order: 1;
                }
            }

            &-SubPrice,
            &-NetPriceLabel,
            &-GrossPriceLabel {
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
                color: $text-color-2;
            }

            &-NetPriceLabel {
                position: absolute;
                top: 25px;
                left: 0;

                @include mobile {
                    top: 28px;
                }
            }

            &-NetPriceWrapper {
                width: 100%;
                display: flex;

                .ProductPrice-HighPrice {
                    opacity: 0;

                    @include mobile {
                        display: none;
                    }
                }

                .ProductPrice-SubPrice {
                    display: flex;
                    margin: 0;
                }
            }
        }
    }

    &-Qty.Field {
        @include desktop {
            margin-inline-end: 40px;
        }

        @include mobile {
            margin: 0 auto;
        }

        [type="number"] {
            @include desktop {
                min-width: 80px;
            }

            @include mobile {
                min-width: 140px;
            }
        }
    }

    &-AddToCart {
        .Field-Wrapper_type_numberWithControls {
            @include mobile {
                margin-block-end: 20px;
                text-align: center;
            }
        }
    }

    &-AddToCartWrapper {
        margin-block-start: 0;

        .ProductActions-AddToCart {
            margin-inline-end: 0;
        }
    }

    .NotLoggedInPriceInfo {
        margin: 10px 0;
    }

    &-SpecialOfferDescription {
        padding: 10px 20px;
        margin: 10px 0;
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: $background-color-1;
        
        >:not(svg) {
            flex: 1 0;
            margin: 0;
        }
    }
}

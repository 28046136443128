/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductTab {
    &-Button {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        padding: 12.5px;
        text-align: center;
        color: $black;
        text-transform: none;
    }

    &-Item {
        display: flex;
        justify-content: center;
        min-width: 150px;

        &_isActive {
            background-color: inherit;
            color: $primary-color-1;
            position: relative;

            &::after {
                background: $primary-color-1;
                border-radius: 66px;
                bottom: 0;
                content: '';
                height: 2px;
                left: 0;
                position: absolute;
                right: 0;
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductList {
    &.ProductListWidget {
        @include desktop {
            padding-bottom: 0;
        }

        .ProductList-Slider+nav {
            display: none;
        }
    }

    .ProductListWidget-Page {
        &_isGrid {
            margin-top: 30px;
            grid-template-columns: repeat(4, minmax(0, 1fr));

            @include mobile {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }
    }
}
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductDetails {
    &-ExpandableContentHeading {
        @include desktop {
            display: none;
        }
    }

    &-Content {
        @include desktop {
            margin-top: 50px;
        }
    }

    &-ExpandableContentContent {
        .pagebuilder-column:not(:nth-of-type(3)) {
            background-color: $secondary-color-3;
            margin-left: 2px;
            margin-bottom: 2px;
            max-width: 230px;
            min-height: 42px;
        }

        .pagebuilder-column:nth-of-type(2) {
            margin-left: 2px;
            max-width: 250px;
        }

        div[data-content-type="row"] {
            margin-bottom: 2px;

            &:last-of-type {
                @include mobile {
                    margin-bottom: 16px;
                }
            }
        }

        .pagebuilder-column {
            padding: 10px 5px;

            @include desktop {
                min-width: 210px;
                padding: 10px 20px;
            }

            @include mobile {
                min-width: 50%;
            }

            p {
                margin: 0;
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
                color: $black;
            }
        }
    }
}

.DetailsRowWrapper {
    display: flex;

    @include mobile {
        &:nth-last-of-type(1) {
            margin-bottom: 20px;
        }
    }

    .DetailsRow {
        width: 50%;
        max-width: 210px;
        min-height: 42px;
        margin-bottom: 2px;
        margin-left: 2px;
        background-color: $secondary-color-3;
        padding: 10px 20px;
        font-size: 16px;

        @include mobile {
            font-size: 11px;
        }

        li {
            font-size: 16px;

            @include mobile {
                font-size: 13px;
            }
        }

        li::before {
            content: none;
        }
    }

    .DetailsRow:nth-of-type(1) {
        max-width: 230px;

        @include mobile {
            max-width: 130px;
        }
    }

    .DetailsRow:nth-of-type(2) {
        max-width: 320px;

        @include mobile {
            max-width: 200px;
            width: 60%;
        }
    }
}
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductInformation {
    &-Wrapper {
        @include desktop {
            padding: 0;
        }
    }

    &-Content {
        @include desktop {
            margin-bottom: 0;
        }
    }

    &-Description {
        @include description-tags {
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: $text-color-2;
        }

        @include mobile {
            line-height: 140%;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductCareStepByStep {
    &-ExpandableContentHeading {
        @include desktop {
            display: none;
        }
    }

    &-ExpandableContentContent {
        > div {
            background: $secondary-color-5;
            margin-bottom: 84px;
            padding: 10px 20px;
            min-height: 86px;

            @include mobile {
                margin-top: 107px;
                margin-bottom: 16px;
                padding: 10px;
            }

            &:nth-of-type(odd) {
                .pagebuilder-column:nth-of-type(2) {
                    padding: 0 30px;
                }
            }

            &:nth-of-type(even) {
                .pagebuilder-column:nth-of-type(2) {
                    @include mobile {
                        order: -1;
                    }
                }
            }

            .pagebuilder-column-line {
                @include mobile {
                    flex-direction: column;
                }
            }
        }
    }
    &-Content {
        @include desktop {
            max-width: 900px;
            margin: 87px auto;
        }

        .pagebuilder-column {
            @include mobile {
                width: 100% !important;
                padding: 0 !important;
            }
        }

        .pagebuilder-column > div:only-child > p {
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: absolute;
            top: -40px;
            width: 150px;
            height: 150px;
            background-color: $primary-color-3;
            font-family: $font-poltawski-nowy;
            font-size: 32px;
            font-style: italic;
            font-weight: 300;
            line-height: 120%;
            letter-spacing: -0.96px;

            @include mobile {
                width: 100px;
                height: 100px;
                font-size: 24px;
                letter-spacing: -0.72px;
                top: -100px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        [data-content-type="heading"] {
            font-size: 16px;
            font-weight: 700;
            font-family: $font-lato;
            font-style: normal;
            margin: 0;
            line-height: 140%;

            @include mobile {
                margin-bottom: 10px;
            }

            + div p {
                font-size: 16px;
                color: $text-color-2;
                margin: 0;
            }
        }
    }
}

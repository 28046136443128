/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductLinks {
    &-SliderLabel {
        font-size: 16px;
        color: $text-color-2;
        font-weight: 400;
        line-height: 140%;
        margin-top: 10px;
    }

    &-SliderLink {
        width: 93px;
        height: 93px;

        &_isNotAvailable {
            opacity: 0.5;
        }
    }

    &-Slider {
        margin-top: 10px;

        .ProductSlider-Slider {
            .slick-list {
                padding: 10px 0 35px;
            }

            .slick-arrow {
                top: 50%;
                transform: translateY(-50%);
            }

            .slick-next {
                right: -11px;
            }

            .slick-prev {
                left: -5px;
            }

            .slick-slide {
                padding: 0;

                a {
                    display: inline-block;
                    border: 2px solid transparent;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                    color: $black;
                    line-height: 115%;

                    @include mobile {
                        font-size: 14px;
                    }

                    &:hover {
                        border-color: $secondary-color-4;
                    }
                }
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --price-color: #{$black};
    --price-with-discount-color: #{$black};
    --price-discount-color: #{$primary-color-1};
}

[dir="ltr"] .ProductPrice {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: $black;

    @include mobile {
        font-size: 20px;
        line-height: 26px;
    }

    &-PriceValue {
        margin-top: 0;

    }

    &-HighPrice {
        opacity: 1;
        font-weight: 400;
        font-size: 14px;
        color: $text-color-2;
        line-height: 18px;
        width: 100%;
        margin: 0 0 2px 0;
        position: absolute;
        top: -20px;

        @include mobile {
            font-size: 12px;
            line-height: 16px;
            top: -18px;
        }
    }

    &,
    &-BundleFrom,
    &-BundleTo {
        &_hasDiscount {
            .ProductPrice-PriceValue {
                color: var(--price-discount-color);
            }
        }
    }

    &-GrossLabel {
        font-weight: 400;
        font-size: 14px;
        margin-left: 5px;
    }
}

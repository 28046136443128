/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .ProductCard {
    --image-size-grid-desktop: 295px;
    --image-size-grid-mobile: 146px;
    --image-size-list-desktop: 175px;

    &_isPlaceholder {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &_layout_list {
        padding: 15px 15px 10px;
        margin-bottom: 10px;

        .ProductCard-FigureReview {
            padding: 0;
        }

        .ProductCard-FigureReview,
        .Image,
        .ProductCard-Figure,
        .Image-Image {
            max-height: var(--image-size-list-desktop);
        }

        .ProductCard-MainInfo .ProductCard-VisibleAttributes {
            margin-top: 55px;
        }
    }

    &_layout_grid {
        .ProductCard-FigureReview,
        .Image,
        .ProductCard-Figure,
        .Image-Image {
            max-height: var(--image-size-grid-desktop);

            @include mobile {
                max-height: var(--image-size-grid-mobile);
            }
        }

        .NotLoggedInPriceInfo {
            margin-top: 56px;
            margin-bottom: 10px;
        }
    }

    &-FigureReview {
        @include desktop {
            padding: 15px 15px 0;
        }
    }

    &-Content {
        @include desktop {
            padding: 11px 15px 6px;
        }

        @include mobile {
            padding: 12px 0;
        }

        &_layout_list {
            display: flex;
            width: 100%;
            flex-direction: revert;
            justify-content: space-between;
            max-width: unset;
            padding: 0;
            margin-left: 11px;

            @include ultra-narrow-desktop {
                flex-wrap: wrap;
            }
        }
    }

    &-MainInfo {
        max-width: 295px;
        width: 100%;

        .ProductCard-VisibleAttributes {
            margin-top: 65px;
            justify-content: flex-start;

            span + span {
                margin-left: 40px;
            }
        }
    }

    &-MainActions {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        @include ultra-narrow-desktop {
            align-items: flex-start;
            margin-top: 20px;
        }

        .ProductCard-ActionWrapper {
            margin-top: 75px;

            .AddToCart {
                min-width: 133px;
                margin-right: 0;
            }

            button {
                margin-right: 0;
            }
        }

        .ProductCard-PriceWrapper {
            margin: 0;
            text-align: right;

            @include ultra-narrow-desktop {
                text-align: left;
            }
        }
    }

    &-Footer {
        display: block;
        padding: 0 15px 15px;
        background-color: $white;

        @include mobile {
            padding: 0 0 15px;
        }
    }

    &-Name {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        padding: 0;
        color: $black;
        margin: 0 auto 6px;

        @include mobile {
            font-size: 14px;
            line-height: 20px;
        }
    }

    &-ShortDescription {
        height: 72px;

        @include text-in-x-lines(4);

        @include mobile {
            height: 34px;

            @include text-in-x-lines(2);
        }

        &,
        p {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $text-color-2;
        }
    }

    &-PriceWrapper {
        height: 50px;
        margin: 22px 0 0;

        @include mobile {
            height: 64px;
            margin: 6px 0 0;
        }
    }

    .ProductPrice {
        min-height: 0;

        &-SubPrice {
            color: $text-color-2;
            margin-top: 0;
        }
    }

    &-AddToCartWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 20px;
        margin: 0;

        @include mobile {
            column-gap: 0;
        }

        .AddToCart {
            max-width: 133px;
        }
    }

    .AddToCart {
        min-width: auto;

        @include mobile {
            height: 40px;
            max-width: unset;
            font-size: 14px;

            .CartIcon {
                width: 18px;
                margin: 0 0 0 6px;
            }
        }
    }

    &-Qty {
        margin-top: 0;
        max-width: 142px;

        @include mobile {
            display: none;
        }
    }

    &-VisibleAttributes {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 6px;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 12px;
        }

        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $text-color-2;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductPage {
    @include mobile {
        margin-top: 0;
        margin-inline: 14px;
    }

    &-Wrapper {
        @include desktop {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-column-gap: 20px;
            padding-bottom: 0;
            margin: 25px auto 50px auto;
        }
    }

    .ProductLinks {
        @include mobile {
            margin-top: 0;
        }
    }

    .ProductBenefits {
        margin: 50px auto;
        scrollbar-width: none;

        @include desktop {
            margin: 75px auto;
        }
    }
}

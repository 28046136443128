/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

// COLORS
$white: #ffffff ;
$black: #25131E;

$text-color: $black;
$text-color-2: #725667;
$text-color-3: #AB94A2;

$primary-color-1: #AC1C42;
$primary-color-2: #80122F;
$primary-color-3: #D04469;

$secondary-color-1: #F5DDD0;
$secondary-color-2: #ECC8B4;
$secondary-color-3: #FFF1E9;
$secondary-color-4: #EFC1CE;
$secondary-color-5: #FBEFF3;
$secondary-color-6: #FFCF96;

$background-color-1: #FBEFF3;
$background-color-2: #25131E;
$background-color-3: #F4F3F3;

$error-color-1: #AC1C42;
$error-color-2: #FFDDDF; 

$disabled-color-1: #AB94A2;
$disabled-color-2: #D5C6CF;

$info-color-1: #F5DDD0;

$success-color-1: #EFC1CE;

$extra-color-1: #252525;

$vegan-color: #364818;

$default-primary-base-color: $primary-color-1;
$default-primary-dark-color: darken($default-primary-base-color, 15%);
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: $background-color-1;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);

// FONTS
$font-poltawski-nowy: 'Poltawski Nowy', sans-serif;
$font-lato: 'Lato', sans-serif;
$font-primary: $font-lato;
$font-secondary: $font-poltawski-nowy;
$font-muli: $font-primary;
$font-standard-size: 62.5%;

// SPACING
$content-wrapper-padding-desktop: 20px;
$content-wrapper-padding-mobile: 14px;
$content-wrapper-padding-mobile-small: 6px;

//BORDERS
$divider-border: 2px solid $secondary-color-4;
